import React, { useRef, useState, useEffect } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

// import async from 'react-select/dist/declarations/src/async';

const urlApi = '/order_products';

// Форма додавання товару до замовлення
const FormProductOrders = (props) => {
    console.log("props", props);


    const editorRef = useRef(null);
    // Дані про замовлення
    const data = props.data;
    const fields = {
        name: '',
        status: 'approved',
        count: 0,
        discount: 0,
        product: '',
        preOrder: `/api/pre_orders/${props.data.id}`,
        measurementUnit: '',
    };
    const [values, setValues] = useState(fields);
    const [product, setProduct] = useState({});
    const [measurmentUnit, setMeasurmentUnit] = useState();
    const [selectItems, setSelectItems] = useState({
        product: {},
        measurementUnit: {}
    });

    useEffect(() => {
        if (props.id != undefined) {
            AxiosInstance.get(`${urlApi}/${props.id}`, { headers: { 'accept': 'application/json' } }).then((response) => {
                let data = response.data;
                setValues({
                    ...values,
                    name: data.name,
                    status: data.status,
                    count: data.count,
                    discount: data.discount,
                    product: data.product.id,
                });

                setProduct({
                    value: data.product.id,
                    label: data.product.name,
                });
            });
        }
        AxiosInstance.get('/measurment_units', { headers: { 'accept': 'application/json' } }).then((response) => {
            return setMeasurmentUnit(response.data.map((result) => ({
                label: result.name,
                value: result.id,
            })));
        });

    }, [props]);

    const handleInputChange = (e) => {
        console.log(e);
        const { name, value, type } = e.target;
        setValues({
            ...values,
            [name]: type === "number" ? parseFloat(value, 10) : value
        });
    };

    const handleSubmit = event => {
        event.preventDefault();
        let v = true;
        let vError = '';

        if (values.product == '') {
            v = false;
            vError += "-> Товар\n";
        }
        if (values.count == '' || values.count <= 0) {
            v = false;
            vError += "-> Кількість\n";
        }
        if (values.measurementUnit == '') {
            v = false;
            vError += "-> Одиниці виміру\n";
        }

        if (!v) {
            alert("Ви не заповнили обов'язкові поля:\n" + vError);
        }



        if (v) {
            if (props.id != undefined) {
                const response = AxiosInstance.put(`${urlApi}/${data.id}`, values);
            } else {
                const response = AxiosInstance.post(urlApi, values).then((response) => {

                    if (response.status == 201) {

                        return props.actionShowModal(false);
                    }
                });
            }
        }
    }


    /** Товари */
    const [selectedOption, setSelectedOption] = useState(null);
    const loadOptions = async (inputValue) => {
        return await AxiosInstance.get('/products?name=' + inputValue, { headers: { 'accept': 'application/json' } }).then((response) => {
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
    };
    const handleChange = (value, name) => {

        setValues({
            ...values,
            [name]: value.value,
            product: (name == 'product') ? `/api/products/${value.value}` : values.product,
            measurementUnit: (name == 'measurementUnit') ? `/api/measurment_units/${value.value}` : values.measurementUnit
        });
        setSelectItems({
            ...selectItems,
            [name]: value
        });

        console.log(value);
        console.log(values);
        console.log(selectItems);

    };

    return (
        <>
            <h1>Test</h1>
            <CForm onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-12'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Товар</CFormLabel>
                        {/* <AsyncSelect */}
                        <Select
                            cacheOptions
                            loadOptions={loadOptions}
                            value={selectItems.product}
                            className="flex-grow-1"
                            onChange={(value) => { handleChange(value, 'product') }}
                        />

                        {/* <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions
                            value={selectedOption}
                            onChange={handleChange}
                            className="flex-grow-1"
                        /> */}

                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Кількість</CFormLabel>
                        <CFormInput type="number" id="exampleFormControlInput2" name='count' value={values.count} onChange={handleInputChange} />
                    </div>
                    <div className='col'>
                        <div className='col'>
                            <CFormLabel htmlFor="exampleFormControlInput1">Одиниці виміру</CFormLabel>
                            <Select options={measurmentUnit} value={selectItems.measurementUnit} onChange={(value) => { handleChange(value, 'measurementUnit') }} />
                        </div>
                    </div>
                    <div className='col-2 d-flex justify-content-center align-items-end'>
                        <CButton type="submit" color="success">Зберегти</CButton>
                    </div>
                </div>


            </CForm>
        </>
    );
}

export default FormProductOrders;